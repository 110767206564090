import styled from 'styled-components';
import { fonts } from '../../ui/common';
import { media, colorsBar } from '../../../../ui/common';

export const Section = styled.section`
  background-color: #f9f7f6;
  padding-top: 70px;
  padding-bottom: 30px;
`;

export const GridContainer = styled.div`
  ${media.medium`
    display: grid;
    gap: 30px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  `}
`;

export const GridItem = styled.div`
  background-image: url(${(props) =>
    props.backgroundImage ? props.backgroundImage : ''});
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ${fonts.neueMachina.name};
  font-weight: ${fonts.neueMachina.weight.regular};
  color: ${colorsBar.white};
  margin-bottom: 30px;
  padding: 50px 0;

  span {
    font-size: 22px;
    line-height: 1.2;

    &.number {
      font-size: 50px;
    }
  }
  ${media.medium`
    margin-bottom: 0;
    padding: 0;
  `}
`;

export const TextContainer = styled(GridItem)`
  background-color: ${colorsBar.white};
  padding: 30px 30px 50px;
  align-items: flex-start;
  ${media.medium`
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
    padding: 30px 30px 80px;
  `}
`;

export const Title = styled.h4`
  font-size: 30px;
  line-height: 1.3;
  font-family: ${fonts.neueMachina.name};
  font-weight: ${fonts.neueMachina.weight.regular};
  margin: 0 0 10px;
  color: ${colorsBar.blue};
`;

export const Text = styled.p`
  font-size: 20px;
  line-height: 180%;
  margin: 0 0 30px;
  color: ${colorsBar.blueDark};
  font-family: ${fonts.montserrat.name};
  font-weight: ${fonts.montserrat.weight['400']};
`;
