import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Container } from '../../ui/common';
import {
  Section,
  GridContainer,
  GridItem,
  TextContainer,
  Title,
  Text,
} from './styled';
import corals from '../../../../images/product-page/evachill-blue/goal/01.jpg';
import devices from '../../../../images/product-page/evachill-blue/goal/02.jpg';
import ProgressBar from './ProgressBar';

function Goal() {
  const intl = useIntl();

  return (
    <Section>
      <Container>
        <GridContainer>
          <TextContainer>
            <Title>
              <FormattedMessage id="evachill.blue.goal.title" />
            </Title>
            <Text>
              <FormattedMessage id="evachill.blue.goal.description" />
            </Text>
            <Title>
              <FormattedMessage id="evachill.blue.goal.doing.title" />
            </Title>
            <ProgressBar max={10000} value={2834} />
          </TextContainer>
          <GridItem backgroundImage={corals}>
            <span className="number">20 000</span>
            <span>
              {intl.formatMessage({ id: 'evachill.blue.goal.corals' })}
            </span>
          </GridItem>
          <GridItem backgroundImage={devices}>
            <span className="number">10 000</span>
            <span>
              {intl.formatMessage({ id: 'evachill.blue.goal.devices' })}
            </span>
          </GridItem>
        </GridContainer>
      </Container>
    </Section>
  );
}

export default Goal;
