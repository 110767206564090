import React from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';
import { colorsBar } from '../../../../ui/common';

const Bar = styled.div`
  position: relative;
  background-color: ${(props) => props.bg};
  border-radius: 200px;
  height: 45px;
  width: 100%;
`;

const FillContainer = styled.div`
  border-radius: 200px 0px 0px 200px;
  background-color: ${(props) => props.bg};
  width: ${(props) => props.width}%;
  height: 100%;
  position: relative;
`;

const Float = styled.div`
  background-color: ${(props) => props.bg};
  position: absolute;
  right: 0;
  top: 0;
  width: 2px;
  height: calc(100% + 4px);
`;

const Number = styled.div`
  color: ${(props) => props.color};
  font-size: 22px;
  line-height: 33px;
  position: absolute;
  bottom: -40px;
  left: 0;
`;

function ProgressBar({ max, value, colorSchema }) {
  const { bg, fill, float, number } = colorSchema;
  const fillWidth = (value / max) * 100;

  return (
    <Bar bg={bg}>
      <FillContainer bg={fill} width={fillWidth}>
        <Float bg={float}>
          <Number color={number}>{value}</Number>
        </Float>
      </FillContainer>
    </Bar>
  );
}

export default ProgressBar;

ProgressBar.propTypes = {
  max: PropType.number,
  value: PropType.number,
  colorSchema: PropType.shape({
    bg: PropType.string,
    fill: PropType.string,
    float: PropType.string,
    number: PropType.string,
  }),
};

ProgressBar.defaultProps = {
  max: 10000,
  value: 1132,
  colorSchema: {
    bg: '#D6EDF2',
    fill: colorsBar.blue,
    float: colorsBar.blueDark,
    number: colorsBar.blueDark,
  },
};
